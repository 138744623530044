import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";
import moment from "moment";

const initialState = {
  paymentMeans: undefined,
  loading: false,
};

export const getPaymentMeans = createAsyncThunk(
  //action type string
  "/GET_PAYMENT_MEANS",
  // callback function
  async (_, thunkAPI) => {
    const res = await axiosService.get("/customer/connected");

    let paymentMean = res.data.paymentMeans
      .filter((paymentMean) => (paymentMean.endDateTime === undefined || moment(paymentMean.endDateTime).isAfter(moment())))
    .sort((a, b) => {
      return new Date(b.dateTimeCreate) - new Date(a.dateTimeCreate);
    })[0];

    if (!paymentMean) {
      return undefined;
    }

    const bankAccountPaymentMean = {
      bankName: paymentMean.name,
      iban: paymentMean.characteristics.iban,
      bic: "string",
      holder: paymentMean.characteristics.holder,
      country: paymentMean.characteristics.country,
      city: paymentMean.characteristics.city,
      postCode: paymentMean.characteristics.postCode,
      street: paymentMean.characteristics.street,
      mandate: paymentMean.characteristics.mandateId,
    };

    return bankAccountPaymentMean;
  },
  //   Add condition is already in the store
  {
    // condition: (_, thunkAPI) => {
    //   const data = thunkAPI.getState();
    //   const fetchPaymentMeans = data.paymentMeansData.paymentMeans;

    //   if (Object.keys(fetchPaymentMeans).length > 0) {
    //     // Already fetched or in progress, don't need to re-fetch
    //     return false;
    //   }
    // },
  }
);

export const paymentMeansSlice = createSlice({
  name: "paymentMeans",
  initialState,
  reducers: {
    // addBillingCharge: (state, action) => {
    //   state.push(action.payliad);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMeans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentMeans.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.paymentMeans = payload;
      })
      .addCase(getPaymentMeans.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default paymentMeansSlice.reducer;
