import { Grid } from "@mui/material";
import ContainerItem from "components/atoms/ContainerItem/ContainerItem";
import BlocCoordonnee from "components/organisms/BlocCoordonnee";
import BlocIdentifiantConnexion from "components/organisms/BlocIdentifiantConnexion";
import BlocMoyenPaiement from "components/organisms/BlocMoyenPaiement";
import BlocPreferenceCommunication from "components/organisms/BlocPreferenceCommunication";
import LayoutPage from "components/organisms/Layout/LayoutPage";
import { useEffect, useState } from "react";
// import PopinModifierNumero from "components/molecules/PopinModifierNumero";

import { getPaymentMeans } from "core/services/API/PaymentMeans/paymentMeansSlice";
import {
  getPrefCoMean,
  putPrefCoMean,
} from "core/services/API/PreferedCommunicationMean/PreferedCommunicationMeanSlice";
import { getSsoLink } from "core/services/API/SSOZendesk/getLinkSSO";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./styles/infosperso.scss";

const InfosPerso = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const prefCoEmail = useSelector(
    (state) =>
      state?.prefCoMean?.prefCoMean?.customerPreference?.characteristics
        ?.preferredCom_Email
  );

  const prefCoPhone = useSelector(
    (state) =>
      state?.prefCoMean?.prefCoMean?.customerPreference?.characteristics
        ?.preferredCom_Phone
  );

  const prefCoSMS = useSelector(
    (state) =>
      state?.prefCoMean?.prefCoMean?.customerPreference?.characteristics
        ?.preferredCom_Sms
  );

  const loadingPrefCo = useSelector((state) => state?.prefCoMean?.loading);

  // const [openPhone, setOpenPhone] = useState(false);
  // const [openPass, setOpenPass] = useState(false);

  const infos = useSelector((state) => state.individualState.infos);
  const user = useSelector((state) => state.userInfo.user);
  const paymentMeansData = useSelector(
    (state) => state.paymentMeansData?.paymentMeans
  );
  const [infoPaymentMeans, setInfoPaymentMeans] = useState();

  function updatePreferredCom(feature, value) {
    let preferredCom = {
      preferredCom_Sms: prefCoSMS,
      preferredCom_Email: prefCoEmail,
      preferredCom_Phone: prefCoPhone,
    };
    preferredCom[feature] = value?.toString();
    const update = new Promise((resolve, reject) => {
      resolve(dispatch(putPrefCoMean(preferredCom)));
    });
    update.then((res) => {
      return setTimeout(() => {
        // *** ATTENTION ***

        //    Attente de prise en compte par tripica obligatoire, sinon le GET est lancé trop tôt
        //    et les data envoyées ne sont pas prises en compte par Tripica

        // *** ATTENTION ***
        dispatch(getPrefCoMean());
      }, 2000);
    });
  }

  useEffect(() => {
    dispatch(getPaymentMeans());
    dispatch(getPrefCoMean());
  }, [dispatch]);

  useEffect(() => {
    if (paymentMeansData) {
      setInfoPaymentMeans(paymentMeansData);
    }
  }, [paymentMeansData]);

  return (
    <LayoutPage>
      <ContainerItem container spacing={3.5} className="container-infosperso">
        <Grid item xs={12} md={8}>
          <BlocIdentifiantConnexion
            email={user?.email}
            onClickEmail={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=9478031790749")}

            onClickPass={() => history.push("/password/forgotten")}
          />

          <BlocCoordonnee
            titulaire={infos}
            cotitulaires={infos?.relatedParty}
            adresse={
              infos?.contactMediums
              ?.filter((i) => i?.type === "BILLING_ADDRESS")
            .sort((a, b) => b?.startDateTime - a?.startDateTime)?.[0]?.medium
            }
            numeros={infos?.contactMediums
              ?.filter((i) => i?.type === "MOBILE_CONTACT_NUMBER")
              ?.map((n) => n?.medium?.number)}

            onClickAskModif={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=9179140082205&tf_subject=Je modifie mon adresse de facturation pour les prochaines factures")}

            onClickModifPhone={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=9179243004701&tf_subject=Je modifie mon numéro de téléphone")}
          />

          {/* <PopinModifierNumero
            open={openPhone}
            onClose={() => setOpenPhone(false)}
          /> */}

          <BlocMoyenPaiement
            infosPaiment={infoPaymentMeans}
            onClickModifyIBAN={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=9452548116253&tf_subject=Je modifie mon IBAN pour les prochains prélèvements")}

          />
        </Grid>

        <Grid item xs={12} md={4} className="gridJonction">
          <BlocPreferenceCommunication
            prefCoEmail={prefCoEmail === "true" ? true : false}
            prefCoPhone={prefCoPhone === "true" ? true : false}
            prefCoSMS={prefCoSMS === "true" ? true : false}
            onChangeEmail={(value) => {
              updatePreferredCom("preferredCom_Email", value);
            }}
            onChangePhone={(value) => {
              updatePreferredCom("preferredCom_Phone", value);
            }}
            onChangeSMS={(value) => {
              updatePreferredCom("preferredCom_Sms", value);
            }}
            loading={loadingPrefCo}
          />
        </Grid>
      </ContainerItem>
    </LayoutPage>
  );
};

export default InfosPerso;
