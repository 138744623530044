import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";

const initialState = {
  files: [],
  loading: false,
};

export const getFiles = createAsyncThunk(
  //action type string
  "/GET_FILES",
  // callback function
  async (billingOuid, thunkAPI) => {
    const res = await axiosService.get("/file");


    const billingsfiles = await axiosService.get(
      `/billingAccount/settlement/${billingOuid}`
    );

    const billingsAccountBalance = await axiosService.get(
      `/billingAccountBalance/billingAccountOuid/${billingOuid}`
    );

    const contractFiles = res.data.flatMap((v) => {
      return v.characteristics.type === "CONTRACT" ? [v] : [];
    });
    const invoicesFiles = res.data.flatMap((v) => {
      return v.fileName.charAt(0) === "F" ? [v] : [];
    });
    const sepaFiles = res.data.flatMap((v) => {
      return v.characteristics.type === "SEPA_MANDATE" ? [v] : [];
    });
    const echeancierFiles = res.data.flatMap((v) => {
      return v.characteristics.type === "ECHEANCIER" ? [v] : [];
    });

    const invoicesList = [];

    billingsfiles?.data?.forEach((v) => {
      const findStatus = () => {
        let first = billingsAccountBalance?.data
        .filter(
          (f) => f.settlementNoteAdviceOuid === v.ouid
        )
        .sort((v1, v2) => v1.dateTimeCreate - v2.dateTimeCreate)[0];
        console.log(v.taxIncludedAmount, billingsAccountBalance?.data
          .filter(
            (f) => f.settlementNoteAdviceOuid === v.ouid
          )
          .sort((v1, v2) => v1.dateTimeCreate - v2.dateTimeCreate))
        //console.log(v.taxIncludedAmount,first, v.ouid)
        // console.log(v.taxIncludedAmount,billingsAccountBalance?.data
        //   .filter(
        //     (f) => f.settlementNoteAdviceOuid === v.ouid
        //   ))
        return first?.accountBalance === 0 ? "PAID" : "PENDING";
  
    }
      
      invoicesList.push({
        name: v.id,
        ref: v.id,
        state: v.state,
        status: "",
        billDate: v.billDate,
        echeance: v.paymentDueDate,
        startDateTime: v.startDateTime,
        endDateTime: v.endDateTime,
        taxExcludedAmount: v.taxExcludedAmount,
        taxIncludedAmount: v.taxIncludedAmount,
        openAmount: findStatus.openAmount,
        characteristics: {
          type: "Factures",
        },
      });
    });

    let billingAccountBalance = billingsAccountBalance?.data[0].accountBalance

    const newInvoicesList = invoicesList.map((v) => {
      billingAccountBalance = billingAccountBalance + v.taxIncludedAmount
      let hasBeenPaid = billingAccountBalance > 0 ? true : false
      return {
        ...v,
        status: hasBeenPaid ? "PAID" : "PENDING"
      }
    })

    console.log(newInvoicesList)

    const newList = [];
    invoicesFiles.forEach((l) => {
      const name = l.fileName.replace(".pdf", "");
      newInvoicesList.find((o) =>
        o.name === name
          ? newList.push({
              ...o,
              idFile: l.ouid,
              mediaType: l.mediaType,
              fileNamePDF: l.fileName,
            })
          : ""
      );
    });

    const filesList = {
      contract: contractFiles ? contractFiles : "",
      invoices: newList,
      sepa: sepaFiles ? sepaFiles : "",
      echeancier: echeancierFiles ? echeancierFiles : "",
    };

    return filesList;
  },
  //   Add condition is already in the store
  {
    condition: (_, thunkAPI) => {
      const data = thunkAPI.getState();
      const fetchFiles = data.allFiles.files;

      if (Object.keys(fetchFiles).length > 0) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFiles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.files = payload;
      })
      .addCase(getFiles.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default filesSlice.reducer;
